import { useSnackbar } from 'notistack'
import type { ReactNode } from 'react'
import React, { useCallback, useEffect, createContext, useContext } from 'react'

import { useLocalStorage } from '@src/common/hooks/useLocalStorage'

const AuthContext = createContext<
  | {
      isAuthenticated: boolean
      saveSession: (auth: { sessionId: string; expires: number }) => void
      removeSession: () => void
    }
  | undefined
>(undefined)

export function useAuthContext() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider')
  }
  return context
}

const authSessionKey = 'AUTH'

export function AuthProvider({ children }: { children: ReactNode }) {
  const [session, setSession, removeValue] = useLocalStorage<{
    sessionId: string
    expires: number
  } | null>(authSessionKey, null)

  const { enqueueSnackbar } = useSnackbar()

  const isAuthenticated = !!session

  const saveSession = useCallback(
    ({ sessionId, expires }: { sessionId: string; expires: number }) => {
      setSession({ sessionId, expires })
    },
    [setSession],
  )

  const removeSession = useCallback(() => {
    removeValue()
  }, [setSession])

  useEffect(() => {
    const checkSessionExpiration = () => {
      if (session) {
        const currentTime = Math.floor(Date.now() / 1000)
        if (currentTime >= session.expires) {
          removeSession()
          enqueueSnackbar('Your session has expired. Please log in again.', {
            variant: 'warning',
          })
        }
      }
    }

    const intervalId = setInterval(checkSessionExpiration, 60000)

    return () => clearInterval(intervalId)
  }, [session, removeSession, enqueueSnackbar])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        saveSession,
        removeSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
