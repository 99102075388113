import { text } from 'stream/consumers'

import type { ReactNode } from 'react'
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import type {
  BreadcrumbsContextType,
  Breadcrumb,
} from '@src/common/types/components'

const BreadcrumbsContext = createContext<BreadcrumbsContextType | undefined>(
  undefined,
)

export const BreadcrumbsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [breadcrumbs, setBreadcrumbsState] = useState<Breadcrumb[]>([])
  const [breadcrumbsSet, setBreadcrumbsSet] = useState<Set<string>>(new Set())
  const [textBreadcrumb, setTextBreadcrumbState] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter(Boolean)
    const newBreadcrumbs = pathnames.map((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join('/')}`
      return { label: value.charAt(0).toUpperCase() + value.slice(1), to }
    })

    const newSet = new Set(newBreadcrumbs.map((b) => b.to))
    setBreadcrumbsState(newBreadcrumbs)
    setBreadcrumbsSet(newSet)
  }, [location])

  const addBreadcrumb = (breadcrumb: Breadcrumb) => {
    if (!breadcrumbsSet.has(breadcrumb.to)) {
      setBreadcrumbsState((prev) => [...prev, breadcrumb])
      setBreadcrumbsSet((prev) => new Set(prev).add(breadcrumb.to))
    }
  }

  const updateBreadcrumbs = (newBreadcrumbs: Breadcrumb[]) => {
    const newSet = new Set(newBreadcrumbs.map((b) => b.to))
    setBreadcrumbsState(newBreadcrumbs)
    setBreadcrumbsSet(newSet)
  }

  const setTextBreadcrumb = (label: string) => {
    setTextBreadcrumbState(label)
  }

  const clearBreadcrumbs = () => {
    setBreadcrumbsState([])
    setBreadcrumbsSet(new Set())
  }

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        textBreadcrumb,
        setTextBreadcrumb,
        addBreadcrumb,
        updateBreadcrumbs,
        clearBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext)
  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbsProvider')
  }
  return context
}
