import { getLocalStorage } from '@src/common/hooks/useLocalStorage'

export const encodeBase64UrlSafe = (obj: any) => {
  const jsonStr = JSON.stringify(obj)
  return btoa(jsonStr)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

export const prepareAuthHeaders = (headers: any) => {
  const authData = getLocalStorage('AUTH')
  const token = authData ? JSON.parse(authData).sessionId : null

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  return headers
}

export const parseGeoCode = (geoCode: string) => {
  const [lat, lng] = geoCode
    .replace('@', '')
    .split(',')
    .map((coord) => parseFloat(coord.trim()))
  return { lat, lng }
}

export const extractJurisdictionData = (jurisdictionCode: object) => {
  // Get the first key in the object (there should be only one key)
  const key = Object.keys(jurisdictionCode)[0]
  const data = jurisdictionCode[key]

  // Parse the geo_code
  const { lat, lng } = parseGeoCode(data.geo_code)

  return {
    name: data.name,
    lat,
    lng,
  }
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const colorScale: string[] = [
  '#F0A202', // Warm amber/golden yellow
  '#F78888', // Soft coral pink
  '#F7C6C7', // Light blush pink
  '#F0E5D8', // Soft beige/cream
  '#FF6700', // Bright orange
  '#A9CBB7', // Pale mint green
  '#2E4052', // Dark slate blue
  '#8DA7BE', // Cool, muted blue-gray
  '#8A716A', // Muted, warm brown
  '#D9D9D9', // Light gray/silver
]
