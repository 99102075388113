import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root')!)

const environment = import.meta.env.VITE_APP_ENV

if (environment === 'development') {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  )
} else {
  // Production
  root.render(<App />)
}
