import { createTheme as muiCreateTheme } from '@mui/material'
import type { ThemeOptions } from '@mui/material/styles'

import type { LayoutState } from '@src/common/types'
import gridTheme from '@src/theme/grid'
import paletteTheme from '@src/theme/palette'
import shadowTheme from '@src/theme/shadow'
import typographyTheme from '@src/theme/typography'

const createTheme = (theme: LayoutState['theme']) => {
  const themeOption: Partial<ThemeOptions> = {
    palette: paletteTheme(theme),
    typography: typographyTheme(),
    breakpoints: gridTheme(),
    shape: {
      borderRadius: 4,
    },
    spacing: 8,
    shadows: shadowTheme(theme),
    zIndex: {
      appBar: 1100,
      drawer: 1200,
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&:hover': {
              color: theme.palette.common.blueCoral,
            },
          }),
        },
      },
    },
  }

  return muiCreateTheme(themeOption)
}

export { createTheme }
