import { Suspense, type ReactNode, useEffect } from 'react'

const DefaultLayout = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    document.body.classList.add('public-layout')
    const iframe = document.querySelector('iframe')
    if (iframe) {
      iframe.remove()
    }

    return () => {
      document.body.classList.remove('public-layout')
    }
  }, [])
  return <Suspense fallback={<div />}>{children}</Suspense>
}

export default DefaultLayout
