import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import type {
  CheckoutSessionRequest,
  CheckoutSessionResponse,
} from '@src/features/Subscription/types'
import { prepareAuthHeaders } from '@src/utils/helpers'

const apiBaseUrl = import.meta.env.VITE_API_URL

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    createCheckoutSession: builder.mutation<
      CheckoutSessionResponse,
      CheckoutSessionRequest
    >({
      query: (body) => ({
        url: '/create-checkout-session',
        method: 'POST',
        body,
      }),
    }),
    manageSubscription: builder.mutation({
      query: (body) => ({
        url: `/manage-billing`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useCreateCheckoutSessionMutation,
  useManageSubscriptionMutation,
} = subscriptionApi
