import { Route, Routes } from 'react-router-dom'
import { HistoryRouter } from 'redux-first-history/rr6'

import { BreadcrumbsProvider } from '@src/common/hooks/BreadcrumbsContext'
import PrivateLayout from '@src/layouts/PrivateLayout'
import PublicLayout from '@src/layouts/PublicLayout'
import { history } from '@src/store'

import { PrivateRouteWrapper } from './PrivateRoutes'
import { publicRoutesLayout, privateRoutesLayout } from './routes'

const Router = () => {
  return (
    <HistoryRouter history={history}>
      <BreadcrumbsProvider>
        <Routes>
          {privateRoutesLayout.map((route, idx) => (
            <Route
              key={idx + (route.path ?? '')}
              path={route.path}
              element={
                <PrivateLayout>
                  <PrivateRouteWrapper component={() => route.element} />
                </PrivateLayout>
              }
            />
          ))}
          {publicRoutesLayout.map((route, idx) => (
            <Route
              key={idx + (route.path ?? '')}
              path={route.path}
              element={<PublicLayout>{route.element}</PublicLayout>}
            />
          ))}
        </Routes>
      </BreadcrumbsProvider>
    </HistoryRouter>
  )
}

export default Router
