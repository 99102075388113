import type { ReactNode } from 'react'
import { createContext, useContext, useMemo } from 'react'

import { useLocalStorage } from '@src/common/hooks/useLocalStorage'
import type {
  LayoutSidenav,
  LayoutState,
  LayoutTheme,
  LayoutType,
} from '@src/common/types/layout'

const ThemeContext = createContext<LayoutType | undefined>(undefined)

const useLayoutContext = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useLayoutContext must be used within a LayoutProvider')
  }
  return context
}

const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlParams.entries())

  const initialState: LayoutState = {
    theme: params['layout_theme'] === 'dark' ? 'dark' : 'light',
    sidenav: {
      mode: params['sidenav_mode'] === 'mobile' ? 'mobile' : 'default',
      theme: params['sidenav_theme'] === 'light' ? 'light' : 'dark',
      showMobileMenu: true,
    },
    showRightsideBar: false,
  }

  const [settings, setSettings] = useLocalStorage<LayoutState>(
    '__CONFIG__',
    initialState,
  )

  const themeMode: LayoutTheme = settings.theme

  const updateSettings = (newSettings: Partial<LayoutState>) =>
    setSettings((prev) => ({ ...prev, ...newSettings }))

  const updateTheme = (newTheme: LayoutTheme) =>
    updateSettings({ theme: newTheme })

  const updateSidenav = (newSidenav: Partial<LayoutSidenav>) =>
    updateSettings({ sidenav: { ...settings.sidenav, ...newSidenav } })

  const updateShowRightsideBar = (show: LayoutState['showRightsideBar']) =>
    updateSettings({ showRightsideBar: show })

  const resetSettings = () => {
    setSettings(initialState)
  }

  const contextValue = useMemo(
    () => ({
      settings,
      themeMode,
      updateTheme,
      updateSidenav,
      updateShowRightsideBar,
      resetSettings,
    }),
    [settings, themeMode],
  )

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  )
}

export { useLayoutContext, LayoutProvider }
