import { SnackbarProvider } from 'notistack'
import React from 'react'
import { Provider as ReduxStoreProvider } from 'react-redux'

import { AuthProvider } from '@src/common/hooks/useAuthContext'
import { LayoutProvider } from '@src/common/hooks/useLayoutContext'
import { UserProvider } from '@src/common/hooks/useUserContext'
import type { AppProvidersProps } from '@src/common/types'

import { store } from './store'

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => (
  <ReduxStoreProvider store={store}>
    <AuthProvider>
      <LayoutProvider>
        <UserProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={2000}
          >
            {children}
          </SnackbarProvider>
        </UserProvider>
      </LayoutProvider>
    </AuthProvider>
  </ReduxStoreProvider>
)

export default AppProviders
