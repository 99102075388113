import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { encodeBase64UrlSafe } from '@src/utils/helpers'

const apiBaseUrl = import.meta.env.VITE_API_URL

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (user) => ({
        url: 'auth/register',
        method: 'POST',
        body: {
          credentials: encodeBase64UrlSafe(user),
        },
      }),
    }),
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: {
          credentials: encodeBase64UrlSafe(credentials),
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: (email) => ({
        url: `auth/forgotten-password?email=${email}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useResetPasswordMutation,
} = authApi
export default authApi
