import {
  useTheme,
  Box,
  useMediaQuery,
  AppBar as MuiAppBar,
  styled,
} from '@mui/material'
import type { ReactNode } from 'react'
import { Suspense, useState, useEffect, lazy } from 'react'
import { useLocation } from 'react-router-dom'

import BreadcrumbsComponent from '@src/common/components/Breadcrumb'
import { useUser } from '@src/common/hooks/useUserContext'
import type { AppBarProps } from '@src/common/types'

const SubscriptionPlans = lazy(async () => import('@src/features/Subscription'))

const Topbar = lazy(async () => import('@src/layouts/topbar'))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: theme.palette.primary.main,
}))

const PrivateLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme()
  const { isSubscribed } = useUser()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Suspense fallback={<div />}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <>
              {!isSubscribed ? (
                <SubscriptionPlans />
              ) : (
                <Suspense fallback={<div />}>
                  <Box>
                    <AppBar position="fixed" open={true}>
                      <Topbar open={true} showHeader={!isMobile} />
                    </AppBar>
                    <Box
                      component="main"
                      sx={{
                        mx: !isMobile ? 5 : 1,
                        mt: 11,
                        transition: theme.transitions.create('margin', {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.leavingScreen,
                        }),
                        backgroundColor: theme.palette.background.default,
                      }}
                    >
                      <BreadcrumbsComponent />
                      <Box mt={2}>{children}</Box>
                    </Box>
                  </Box>
                </Suspense>
              )}
            </>
          </Box>
        </Suspense>
      </Box>
    </Box>
  )
}

export default PrivateLayout
