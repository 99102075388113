import { useEffect, useState } from 'react'

export function getLocalStorage(key: string): string | null {
  try {
    const value = localStorage.getItem(key)
    return value ? value : null
  } catch (error) {
    console.error('Error getting local storage item', error)
    return null
  }
}

export function useLocalStorage<T>(key: string | undefined, initialValue: T) {
  useEffect(() => {
    const handleStorageChange = () => {
      if (key) {
        const item = window.localStorage.getItem(key)
        if (item) {
          setStoredValue(JSON.parse(item))
        }
      }
    }

    window.addEventListener('storage', handleStorageChange, false)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key])

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (key) {
        const item = window.localStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      }
      return initialValue
    } catch (error) {
      console.error(error)
      return initialValue
    }
  })

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      if (key) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const removeValue = () => {
    try {
      setStoredValue(initialValue)
      if (key) {
        window.localStorage.removeItem(key)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue, removeValue] as const
}
