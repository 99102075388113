import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'

import authApi from '@src/features/auth/AuthServices'
import searchApi from '@src/features/SearchPage/SearchServices'
import { subscriptionApi } from '@src/features/Subscription/SubscriptionServices'
import { userApi } from '@src/features/user/UserServices'

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() })

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      userApi.middleware,
      searchApi.middleware,
      subscriptionApi.middleware,
      routerMiddleware,
    ]),
  reducer: combineReducers({
    router: routerReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  }),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
export const history = createReduxHistory(store)
