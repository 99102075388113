import HomeIcon from '@mui/icons-material/Home'
import { Box, Breadcrumbs, Chip, Typography } from '@mui/material'
import { useTheme, emphasize, styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

import { useBreadcrumbs } from '@src/common/hooks/BreadcrumbsContext'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const isLightMode = theme.palette.mode === 'light'
  const backgroundColor = isLightMode
    ? theme.palette.grey[100]
    : theme.palette.grey[800]
  const textColor = isLightMode
    ? theme.palette.text.primary
    : theme.palette.grey[100]

  return {
    backgroundColor,
    height: theme.spacing(3),
    color: textColor,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
}) as typeof Chip

const BreadcrumbsComponent = () => {
  const { breadcrumbs, textBreadcrumb } = useBreadcrumbs()
  const theme = useTheme()

  return (
    <Breadcrumbs sx={{ mt: 4 }} aria-label="breadcrumb">
      <StyledBreadcrumb
        component={Link}
        to="/"
        label="Home"
        icon={<HomeIcon fontSize="small" />}
      />
      {breadcrumbs.map((crumb) => (
        <Box key={crumb.label} display="flex" alignItems="center">
          {crumb.to ? (
            <StyledBreadcrumb
              component={Link}
              to={crumb.to}
              label={crumb.label}
            />
          ) : (
            <StyledBreadcrumb label={crumb.label} />
          )}
        </Box>
      ))}
      {textBreadcrumb && <StyledBreadcrumb label={textBreadcrumb} />}
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent
