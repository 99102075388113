import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareAuthHeaders } from '@src/utils/helpers'

const apiBaseUrl = import.meta.env.VITE_API_URL

const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    search: builder.query<
      any,
      {
        schema: 'company' | 'person'
        searchText: string
        filters: (string | null)[]
      }
    >({
      query: (data) => ({
        url: '/search/v1',
        method: 'POST',
        body: {
          search_text: data.searchText,
          schema: data.schema,
          filters: data.filters,
        },
      }),
    }),
  }),
})

export const { useSearchQuery } = searchApi
export default searchApi
