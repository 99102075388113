import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuthContext } from '@src/common/hooks/useAuthContext'
import { useUser } from '@src/common/hooks/useUserContext'

export const PrivateRouteWrapper = ({
  component: RouteComponent,
}: {
  component: React.ComponentType
}) => {
  const { isAuthenticated } = useAuthContext()
  const { isSubscribed } = useUser()

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />
  }
  if (isAuthenticated && !isSubscribed) {
    return <Navigate to="/subscribe" replace />
  }

  return <RouteComponent />
}
